<template>
    <default-layout>

        <div class="spainhn-solid-red-background d-flex py-3" style="height:auto; min-height:75px; width:100%">
            <p class="my-auto ps-4 spainhn-small-text text-white" role="button" @click="goToNoticias">Noticias >></p>
            <p class="my-auto ps-1 spainhn-small-text text-white">{{titulo}}</p>
        </div>

        <div class="container px-5 mt-5">

            <div class="d-flex flex-column">
                <div class="mb-5 image-container d-flex align-items-center justify-content-center d-flex mx-auto" style="height:auto; width:940px; max-width:100%">
                    <!-- <youtube class="me-auto" :video-id="videoId" ref="youtube" width="100%" height="240"></youtube> -->
                    <b-img style="height:auto; width:100%; max-width:90vw;" class="align-items-center mx-auto my-auto" 
                        :src="`${imagenesUrl}/entradas_blog/${noticia.blogId}/${noticia.imagen}`" 
                    />
                </div>
            </div>

            <div class="row text-start mb-3">
                <div class="col-12 mx-auto">
                    <div class="text-center pt-0 w-100 mx-auto">
                        <strong class="spainhn-subtitle-black" style="font-size:20pt">{{titulo}}</strong>

                        <p class="text-start font-italic font-weight-bold">{{noticia.resumen}}</p>
                    </div>
                </div>

                <div class="col-12 mx-auto">
                    <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap">
                    </div>
                </div>

                <div class="w-100 border-bottom mb-0" style="height:2vh; border-color:var(--spainhn-rojo-corporativo) !important; border-width: medium !important;"></div>

            </div>

            <div class="row text-start mb-5">
                <div v-html="noticia.contenido"></div>
            </div>

        </div>


    </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
import ApiService from '../services/ApiService';

export default {
    components:{
        DefaultLayout,
    },
    data(){
        return{
            noticia: null,
            titulo: null,
            blogId: null,
            noticiaImagen: null
        }
    },
    setup() {
        
    },
    mounted(){
        this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;
    },
    methods:{
        /**
         * Esta función te lleva a la entrada de blog/noticia seleccionada
         * @param {event} evento - evento que indica el blog al que se quiere ingresar 
         */
        goToNoticias(){
            // console.log(evento);
            this.$router.push(`/blog`)
        }
    },
    computed:{
        selectedIdiomaIndex(){
            let $vm=this;
            let idiomaIndex = 0;
            if($vm.$i18n.locale == 'es'){
                idiomaIndex = 0;
            }
            else if($vm.$i18n.locale == 'en'){
                idiomaIndex = 1;
            }
            else if($vm.$i18n.locale == 'fr'){
                idiomaIndex = 2;
            }
            else if($vm.$i18n.locale == 'de'){
                idiomaIndex = 3;
            }
            return idiomaIndex;
        },
        selectedIdioma(){
            let $vm=this;
            
            return $vm.$i18n.locale;
        },
    },
    created(){
        let $vm = this;
        // console.log(this.$route.params.blogId);
        ApiService.getBlogs(this.$route.params.blogId)
            .then( result => {
                console.log(result);
                $vm.noticia = result.data[0];
                $vm.titulo = $vm.noticia.titulo;
                $vm.blogId = $vm.noticia.blogId;
            })
            .catch(err => {
                console.log(err);
            })
    }
}
</script>