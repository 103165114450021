var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      {
        staticClass: "spainhn-solid-red-background d-flex py-3",
        staticStyle: { height: "auto", "min-height": "75px", width: "100%" },
      },
      [
        _c(
          "p",
          {
            staticClass: "my-auto ps-4 spainhn-small-text text-white",
            attrs: { role: "button" },
            on: { click: _vm.goToNoticias },
          },
          [_vm._v("Noticias >>")]
        ),
        _c("p", { staticClass: "my-auto ps-1 spainhn-small-text text-white" }, [
          _vm._v(_vm._s(_vm.titulo)),
        ]),
      ]
    ),
    _c("div", { staticClass: "container px-5 mt-5" }, [
      _c("div", { staticClass: "d-flex flex-column" }, [
        _c(
          "div",
          {
            staticClass:
              "mb-5 image-container d-flex align-items-center justify-content-center d-flex mx-auto",
            staticStyle: {
              height: "auto",
              width: "940px",
              "max-width": "100%",
            },
          },
          [
            _c("b-img", {
              staticClass: "align-items-center mx-auto my-auto",
              staticStyle: {
                height: "auto",
                width: "100%",
                "max-width": "90vw",
              },
              attrs: {
                src: `${_vm.imagenesUrl}/entradas_blog/${_vm.noticia.blogId}/${_vm.noticia.imagen}`,
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row text-start mb-3" }, [
        _c("div", { staticClass: "col-12 mx-auto" }, [
          _c("div", { staticClass: "text-center pt-0 w-100 mx-auto" }, [
            _c(
              "strong",
              {
                staticClass: "spainhn-subtitle-black",
                staticStyle: { "font-size": "20pt" },
              },
              [_vm._v(_vm._s(_vm.titulo))]
            ),
            _c(
              "p",
              { staticClass: "text-start font-italic font-weight-bold" },
              [_vm._v(_vm._s(_vm.noticia.resumen))]
            ),
          ]),
        ]),
        _c("div", { staticClass: "col-12 mx-auto" }, [
          _c("div", {
            staticClass:
              "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
          }),
        ]),
        _c("div", {
          staticClass: "w-100 border-bottom mb-0",
          staticStyle: {
            height: "2vh",
            "border-color": "var(--spainhn-rojo-corporativo) !important",
            "border-width": "medium !important",
          },
        }),
      ]),
      _c("div", { staticClass: "row text-start mb-5" }, [
        _c("div", { domProps: { innerHTML: _vm._s(_vm.noticia.contenido) } }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }